import React from "react";
import "./Home.css"; // Assuming Tailwind CSS is included globally
import Scheduling_logo from "../images/Scheduling_logo.png";

const Home = () => {
  return (
    <div className="main-container">
      <div className="hero-section flex flex-col md:flex-row items-center justify-center h-screen bg-black px-4">
        <img
          src={Scheduling_logo}
          alt="Scheduling Wizard Logo"
          className="logo max-w-full h-auto md:w-1/3 lg:w-1/4 mb-4 md:mb-0"
        />
        <div className="text-container text-center md:text-left">
          <h1 className="text-gold title text-6xl md:text-7xl font-bold ">
            Scheduling Wizard
          </h1>
          <p className="subtitle text-2xl md:text-4xl mt-2 text-white">
            Schedule Creator & Software Provider
          </p>
        </div>
      </div>
      <div className="slogan-section bg-black text-white text-center py-8">
        <h2 className="text-3xl md:text-5xl">
          - Making Your Scheduling Problems Disappear -
        </h2>
      </div>
      <div className="content-section bg-black py-10 px-4 md:px-20">
        <div className="section mb-10">
          <h2 className="section-title text-3xl md:text-5xl text-gold text-center mb-6">
            Mission
          </h2>
          <p
            className="section-description text-white text-lg md:text-xl text-center leading-relaxed mx-auto"
            style={{ maxWidth: "800px" }}
          >
            Our mission is to leverage advanced optimization techniques to
            refine academic scheduling processes, ensuring they are as
            efficient, accommodating, and responsive to student and teacher
            needs as possible.
          </p>
        </div>
        <br />
        <div className="section mb-10">
          <h2 className="section-title text-3xl md:text-5xl text-gold text-center mb-6">
            We bring
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-center items-center">
            <div className="tag text-black rounded-full text-center py-2 px-4 text-xl md:text-2xl">
              Credibility
            </div>
            <div className="tag  text-black rounded-full text-center py-2 px-4 text-xl md:text-2xl">
              Experience
            </div>
            <div className="tag  text-black rounded-full text-center py-2 px-4 text-xl md:text-2xl">
              Visionary approach
            </div>
          </div>
        </div>
        <br />
        <div className="section mb-10">
          <h2 className="section-title text-3xl md:text-5xl text-gold text-center mb-6">
            What problem do we solve?
          </h2>
          <p
            className="section-description text-white text-lg md:text-xl text-center leading-relaxed mx-auto"
            style={{ maxWidth: "800px" }}
          >
            Course scheduling is a tedious, complex, and time-intensive task for teachers and administrators across the 
            American School System. We alleviate that pain through our optimization software solution and a consulting-based approach. 
            Utilizing AI technology and optimization techniques, we create effective schedules in minimal time.
          </p>
        </div>
        <br />
        <div className="section mb-10">
          <h2 className="section-title text-3xl md:text-5xl text-gold text-center mb-6">
            How we make course scheduling seamless...
          </h2>
          <div className="service-offerings grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="service-box bg-gray-700 rounded-lg p-6 shadow-lg transition duration-300 ease-in-out hover:scale-110 text-center">
              <h3 className="service-title text-2xl md:text-4xl text-gold mb-2">
                Academic Experience
              </h3>
              <p className="text-white service-description text-base md:text-xl">
                We provide expert management and understanding of your
                scheduling needs to achieve your best academic experience.
              </p>
            </div>

            <div className="service-box bg-gray-700 rounded-lg p-6 shadow-lg transition duration-300 ease-in-out hover:scale-110 text-center">
              <h3 className="service-title text-2xl md:text-4xl text-gold mb-2">
                Efficient Management
              </h3>
              <p className="text-white service-description text-base md:text-xl">
                We ensure that every aspect of your scheduling process is
                executed flawlessly and efficiently.
              </p>
            </div>

            <div className="service-box bg-gray-700 rounded-lg p-6 shadow-lg transition duration-300 ease-in-out hover:scale-110 text-center">
              <h3 className="service-title text-2xl md:text-4xl text-gold mb-2">
                Customized Schedule
              </h3>
              <p className="text-white service-description text-base md:text-xl">
                Our team has automized the challenge of scheduling students and
                teachers for many types of public and private institutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
