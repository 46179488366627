import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer"; // Import the Footer component

function App() {
  useEffect(() => {
    document.title = "Scheduling Wizard";
  }, []); // The empty array ensures this runs only once after the initial render

  return (
    <Router>
      <div className="main-container flex flex-col min-h-screen">
        {" "}
        {/* Flex container to hold the content and footer */}
        <Navbar />
        <div className="flex-grow">
          {" "}
          {/* This div pushes the footer to the bottom */}
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />{" "}
        {/* Footer is outside the Routes but inside the main container */}
      </div>
    </Router>
  );
}

export default App;
