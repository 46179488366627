import React from 'react';

const YoutubeEmbed = ({ embedId }) => (
  <div className='video-responsive' style={{
    border: '2px solid #FFFFFF',
    padding: '5px',
    maxWidth: '853px',
    margin: '0 auto',
    width: 'calc(100% - 10px)' // Subtracting the total horizontal padding from the width
  }}>
    <div style={{
      position: 'relative',
      paddingBottom: '56.25%', // This maintains the 16:9 aspect ratio
      height: '0',
      overflow: 'hidden',
      paddingLeft: '5px', // Padding on the left
      paddingRight: '5px' // Padding on the right
    }}>
      <iframe
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        }}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div>
);

export default YoutubeEmbed;
