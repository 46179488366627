import React from 'react';
import YoutubeEmbed from '../components/YoutubeEmbed'; // Assuming YoutubeEmbed is in the same project directory
import './About.css'

const Services = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white py-10">
      <YoutubeEmbed embedId="fjc2ZtppxWE" />
      <h2 className="text-4xl text-bold text-gold mt-10">Our Service Process</h2>
      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-5 max-w-7xl">
        {steps.map((step, index) => (
          <div key={index} className="bg-gray-800 rounded-lg p-6 shadow-lg hover:scale-105 transition-transform duration-300">
            <h3 className="steps text-2xl font-bold text-gold mb-1">{`Step ${index + 1}`}</h3>
            <h4 className="step-title text-xl font-semibold text-gold mb-4">{step.title}</h4>
            <p className="text-lg">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

// Define the steps in an array for easy maintenance and scalability
const steps = [
  {
    title: "Consultation",
    description: "We discuss your school's needs to understand how to best create a schedule. This is where we gather the relevant information for schedule creation."
  },
  {
    title: "Schedule Creation",
    description: "Utilize the Scheduling Wizard software to create an initial schedule that accommodates all specified needs."
  },
  {
    title: "Continuous Iteration",
    description: "We continuously gather your feedback and refine the schedule, ensuring the process is dynamic to provide the best possible outcomes."
  },
  {
    title: "Finalize",
    description: "We'll hand over the final schedule so you can easily make adjustments whenever necessary, providing you with flexibility and control over your scheduling needs throughout the semester."
  }
];

export default Services;
