// src/pages/Contact.js
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState("Send");

  const sendEmail = (e) => {
    setButtonText("Sending..."); // Change button text to "Sending..."

    // Simulate an API call with setTimeout
    setTimeout(() => {
      setButtonText("Sent!"); // Update the button text to "Sent!" after the "API call"
    }, 2000); // Delay of 2 seconds
    e.preventDefault();

    emailjs
      .sendForm("service_fyr1pym", "template_v6h06eg", form.current, {
        publicKey: "VkoIvl4bCaHrzLvrL",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div
      className="contact-form flex flex-col m-8 p-4 rounded"
      id="contact-form"
    >
      <h2 className="text-gold text-4xl font-bold text-center">Contact Us</h2>
      <p className="text-center mt-4">
        For more information, please email us or fill out the form below.
      </p>
      <div className="flex justify-center mt-6">
        <form ref={form} onSubmit={sendEmail} className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gold-700 text-xs font-bold mb-2"
                htmlFor="from_name"
              >
                Name
              </label>
              <input
                type="text"
                name="from_name"
                id="from_name"
                placeholder="Your Name"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                required
              />
              <label
                className="block uppercase tracking-wide text-gold-700 text-xs font-bold mb-2"
                htmlFor="reply_to"
              >
                E-mail
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="email"
                name="reply_to"
                id="reply_to"
                placeholder="you@example.com"
              />
              <label
                className="block uppercase tracking-wide text-gold-700 text-xs font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>

              <textarea
                name="message"
                id="message"
                placeholder="Your Message"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                required
              ></textarea>
            </div>
          </div>
          <div className="flex flex-wrap justify-center -mx-3 mb-6 ">
            <div className="flex w-full justify-center px-3">
              <button
                className="bg-gold-700 hover:bg-gold-300 text-black font-bold py-2 px-16 rounded"
                type="submit"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
