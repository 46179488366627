import React from 'react';

const Footer = () => {
  return (
    <div className="mt-auto"> {/* This ensures the footer stays at the bottom */}
        <footer className="bg-black text-gold py-8 text-center w-full">
          <div className="container mx-auto px-4">
            <p className="text-base"> {/* Increased font size from text-sm to text-base */}
              © 2024 Scheduling Wizard. All rights reserved.
            </p>
          </div>
        </footer>
    </div>
  );
};

export default Footer;
